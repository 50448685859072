export type CategoryPage = {
  name: string;
  path: string;
  excludeIfAuthenticated: boolean;
  excludeIfUnauthenticated: boolean;
};

export const categoryPages: CategoryPage[] = [
  {
    name: 'forsiden',
    path: '/',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'lovkommentarer og lover',
    path: '/lovkommentarer',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'faglitteratur',
    path: '/faglitteratur',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'tidsskrifter',
    path: '/tidsskrifter',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'akademi',
    path: '/akademi',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'forfattere',
    path: '/forfattere',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'ekspertkommentarer',
    path: '/innsikt/ekspertkommentarer',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'podkast',
    path: '/innsikt/podkaster',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
  {
    name: 'maler',
    path: '/maler',
    excludeIfAuthenticated: false,
    excludeIfUnauthenticated: false,
  },
];
